dmx.Component('swiper', {

    constructor: function(node, parent) {
        this.dynamic = node.hasAttribute('dmx-bind:slides');
        this.updateData = this.updateData.bind(this)
        dmx.BaseComponent.call(this, node, parent);
    },

    initialData: {
        index: 0,
        total: 0,
        isBeginning: false,
        isEnd: false
    },

    attributes: {

        // General

        vertical: {
            type: Boolean,
            default: false
        },

        speed: {
            type: Number,
            default: 300 // Duration of transition between slides (in ms)
        },

        'auto-height': {
            type: Boolean,
            default: false
        },

        effect: {
            type: String,
            default: 'slide' // slide, fade, cube, coverflow, flip
        },
'initial-slide': {
			type: Number,
            default: null
		},
        // Slides Grid

        'space-between': {
            type: Number,
            default: 0
        },

        'slides-per-view': {
            type: String,
            default: 1
        },

        'slides-per-group': {
            type: Number,
            default: 1
        },

        'space-between-sm': {
            type: Number,
            default: null
        },

        'slides-per-view-sm': {
            type: String,
            default: null
        },

        'slides-per-group-sm': {
            type: Number,
            default: null
        },

        'space-between-md': {
            type: Number,
            default: null
        },

        'slides-per-view-md': {
            type: String,
            default: null
        },

        'slides-per-group-md': {
            type: Number,
            default: null
        },

        'space-between-lg': {
            type: Number,
            default: null
        },

        'slides-per-view-lg': {
            type: String,
            default: null
        },

        'slides-per-group-lg': {
            type: Number,
            default: null
        },

        'space-between-xl': {
            type: Number,
            default: null
        },

        'slides-per-view-xl': {
            type: String,
            default: null
        },

        'slides-per-group-xl': {
            type: Number,
            default: null
        },

        'centered-slides': {
            type: Boolean,
            default: false
        },

        'slides-offset-before': {
            type: Number,
            default: 0
        },

        'slides-offset-after': {
            type: Number,
            default: 0
        },

        // Grab Cursor

        'grab-cursor': {
            type: Boolean,
            default: false
        },

        // Freemode

        'free-mode': {
            type: Boolean,
            default: false
        },

        'free-mode-sticky': {
            type: Boolean,
            default: false
        },

        // Loop

        loop: {
            type: Boolean,
            default: false
        },

        'loop-fill': { // loopFillGroupWithBlank
            type: Boolean,
            default: false
        },

        // Components

        pagination: {
            type: String,
            default: null // bullets, fraction, progressbar
        },

        navigation: {
            type: Boolean,
            default: false
        },

        scrollbar: {
            type: Boolean,
            default: false
        },

        autoplay: {
            type: Boolean,
            default: false
        },

        parallax: {
            type: Boolean,
            default: false
        },

        keyboard: {
            type: Boolean,
            default: false
        },

        mousewheel: {
            type: Boolean,
            default: false
        },

        observer: {
            type: Boolean,
            default: false
        },
		
        // Dynamic Data

        slides: {
            type: Array,
            default: false
        }
    },

    methods: {
        slideNext: function(speed, runCallbacks) {
            this.swiper.slideNext(speed, runCallbacks);
        },

        slidePrev: function(speed, runCallbacks) {
            this.swiper.slidePrev(speed, runCallbacks);
        },

        slideTo: function(index, speed, runCallbacks) {
            this.swiper.slideTo(index, speed, runCallbacks);
        },

        slideToLoop: function(index, speed, runCallbacks) {
            this.swiper.slideToLoop(index, speed, runCallbacks);
        },

        slideReset: function(speed, runCallbacks) {
            this.swiper.slideReset(speed, runCallbacks);
        },

        slideToClosest: function(speed, runCallbacks) {
            this.swiper.slideToClosest(speed, runCallbacks);
        },

        autoplayStart: function() {
            this.swiper.autoplay.start();
        },

        autoplayStop: function() {
            this.swiper.autoplay.stop();
        },

        update: function() {
            this.update({});
        }
    },

    events: {
        change: Event,
        rendered: Event
    },

    render: function(node) {
        this.template = node.innerHTML;

        this.container = this.$node = node;
        this.container.classList.add('swiper-container');
        this.container.innerHTML = '';

        this.wrapper = document.createElement('div');
        this.wrapper.className = 'swiper-wrapper';

        this.pagination = document.createElement('div');
        this.pagination.className = 'swiper-pagination';

        this.buttonPrev = document.createElement('div');
        this.buttonPrev.className = 'swiper-button-prev';

        this.buttonNext = document.createElement('div');
        this.buttonNext.className = 'swiper-button-next';

        this.scrollbar = document.createElement('div');
        this.scrollbar.className = 'swiper-scrollbar';

        this.container.appendChild(this.wrapper);

        this.update({});
    },

    update: function(props) {
        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            if (!this.dynamic) {
                this.wrapper.innerHTML = this.template;
                this.$parse(this.wrapper);
            } else {
                if (JSON.stringify(props.slides) != JSON.stringify(this.props.slides)) {
                    this.wrapper.innerHTML = '';
                    this.children.splice(0).forEach(function(child) {
                        child.$destroy();
                    });

                    var slides = dmx.repeatItems(this.props.slides);

                    if (slides.length) {
                        var template = document.createElement('div');
                        template.innerHTML = this.template;

                        var RepeatItem = dmx.Component('repeat-item');
                        var fragment = document.createDocumentFragment();

                        for (var i = 0; i < slides.length; i++) {
                            var child = new RepeatItem(template.cloneNode(true), this, slides[i]);
                            child.$nodes.forEach(function(node) {
                                fragment.appendChild(node);
                                child.$parse(node);
                            });
                            this.children.push(child);
                        }

                        this.wrapper.appendChild(fragment);
                    }
                }
            }

            if (this.props.pagination) {
                this.container.appendChild(this.pagination);
            } else {
                dmx.dom.remove(this.pagination);
            }

            if (this.props.navigation) {
                this.container.appendChild(this.buttonPrev);
                this.container.appendChild(this.buttonNext);
            } else {
                dmx.dom.remove([this.buttonPrev, this.buttonNext]);
            }

            if (this.props.scrollbar) {
                this.container.appendChild(this.scrollbar);
            } else {
                dmx.dom.remove(this.scrollbar);
            }

            var params = {
                direction: this.props.vertical ? 'vertical' : 'horizontal',
                speed: this.props.speed,
                autoHeight: this.props['auto-height'],
                effect: this.props.effect,
                spaceBetween: this.props['space-between'],
                slidesPerView: this.props['slides-per-view'],
                slidesPerGroup: this.props['slides-per-group'],
                centeredSlides: this.props['centered-slides'],
                slidesOffsetBefore: this.props['slides-offset-before'],
                slidesOffsetAfter: this.props['slides-offset-after'],
                grabCursor: this.props['grab-cursor'],
                freeMode: this.props['free-mode'],
                freeModeSticky: this.props['free-mode-sticky'],
                loop: this.props.loop,
                loopFillGroupWithBlank: this.props['loop-fill'],
                pagination: {
                    el: this.props.pagination ? '.swiper-pagination' : null,
                    type: this.props.pagination,
                    clickable: true
                },
                navigation: {
                    prevEl: this.props.navigation ? '.swiper-button-prev' : null,
                    nextEl: this.props.navigation ? '.swiper-button-next' : null
                },
                scrollbar: {
                    el: this.props.scrollbar ? '.swiper-scrollbar' : null,
                    draggable: true,
                    snapOnRelease: true
                },
                autoplay: this.props.autoplay,
                parallax: this.props.parallax,
                keyboard: this.props.keyboard,
                mousewheel: this.props.mousewheel,
                breakpointsInverse: true,
                breakpoints: {},
                observer: this.props.observer,
                observeParents: this.props.observer,
				initialSlide: this.props['initial-slide']
            };

            this.spaceBetween = this.props['space-between'];
            this.slidesPerView = this.props['slides-per-view'];
            this.slidesPerGroup = this.props['slides-per-group'];

            params.breakpoints[576] = this.getBreakpointProps('sm');
            params.breakpoints[768] = this.getBreakpointProps('md');
            params.breakpoints[992] = this.getBreakpointProps('lg');
            params.breakpoints[1200] = this.getBreakpointProps('xl');

            if (window.wapplerDesignView) {
                params.simulateTouch = false;
            }

            // Delayed init, let App Connect first update the slides
            var self = this;
            requestAnimationFrame(function() {
                if (self.swiper) self.swiper.destroy();
                var swiper = self.swiper = new Swiper(self.container, params);
                swiper.on('observerUpdate', self.updateData);
                swiper.on('slideChange', self.updateData);
                swiper.on('slideChange', self.dispatchEvent.bind(self, 'change'));
                self.updateData();
                self.dispatchEvent('rendered');
            });
        }
    },

    updated: function() {
        if (this.swiper && !this.swiper.destroyed) {
            this.swiper.updateSize();
        }
    },

    getBreakpointProps: function(suffix) {
        if (this.props['space-between-' + suffix] != null) {
            this.spaceBetween = this.props['space-between-' + suffix];
        }

        if (this.props['slides-per-view-' + suffix] != null) {
            this.slidesPerView = this.props['slides-per-view-' + suffix];
        }

        if (this.props['slides-per-group-' + suffix] != null) {
            this.slidesPerGroup = this.props['slides-per-group-' + suffix];
        }

        return {
            spaceBetween: this.spaceBetween,
            slidesPerView: this.slidesPerView,
            slidesPerGroup: this.slidesPerGroup
        };
    },

    updateData: function() {
        if (this.swiper && !this.swiper.destroyed) {
            var total = this.swiper.loopedSlides || this.swiper.slides.length;

            this.set('index', total > 0 ? this.swiper.realIndex % total : 0);
            this.set('total', total);
            this.set('isBeginning', this.swiper.isBeginning);
            this.set('isEnd', this.swiper.isEnd);
        }
    }

});
